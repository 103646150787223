import React from 'react'
import {
  CardContainer,
  ClockImage,
  Container,
  Content,
  DescriptionWrapper,
  ImageWrapper,
  ListImage,
  MessageImage,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './ChooseSpecialist.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const ChooseSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>
              Подберите специалиста и запишитесь на сессию уже сегодня
            </Title.H2>
          </TitleWrapper>
          <DescriptionWrapper>
            Принять решение обратиться к психотерапевту может быть непросто, но
            команда YouTalk готова поддержать вас.
          </DescriptionWrapper>

          <CardContainer>
            <Container>
              <ImageWrapper>
                <ClockImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Large semiBold>
                  Подберите подходящего психолога-сексолога с помощью нашего{' '}
                  <a href="/wizard/" target="_blank">
                    алгоритма
                  </a>
                </Text.Large>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <MessageImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Large semiBold>
                  Возможно, вы хотите задать нам вопросы или почитать статьи в{' '}
                  <a href="/blog/" target="_blank">
                    блоге
                  </a>
                  , чтобы утихомирить тревоги и развеять сомнения.{' '}
                </Text.Large>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <ListImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Large semiBold>
                  Гайды и чек-листы помогут вам подготовиться к первой сессии, а
                  психолог будет поддерживать вас на пути к решению проблем.{' '}
                </Text.Large>
              </TextWrapper>
            </Container>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
