import React from 'react'
import styled from 'styled-components'
import { Grid } from '../atoms/Grid'
import { Montserrat, size } from '../constants'
import { QuestionsList } from './QuestionsList'

const QuestionList = styled(({ className }) => (
  <div className={className}>
    <div className="questions-list-seksolog__title">Вопросы</div>
    <QuestionsList
      onlyOnOpenSendGA
      className={className}
      eventGA="getSeksologAnswer"
      page="Seksolog"
    />
  </div>
))`
  .questions-list-seksolog__title {
    color: #333333;
    font-family: ${Montserrat};
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;

    @media (max-width: ${size.sm}) {
      font-size: 32px;
      line-height: 40px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }

  & .questions-list-item__ans {
    max-width: 996px;
  }

  & .questions-list-item__ans > p {
    font-weight: 500;
  }

  & > li > button {
    background-color: transparent;
  }
`

export const QuestionListSeksolog = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <QuestionList />
    </Grid>
  </section>
))`
  background-color: #fff;
`
