import React from 'react'
import { Button } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  Content,
  HeartImage,
  TitleCard,
  Wrapper
} from './Chance.styles'
import { Event as GAEvent } from '../../../components/GA'

export const Chance = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <HeartImage />
          <TitleCard>
            Предоставьте себе шанс получить поддержку и помощь, которые вы
            заслуживаете
          </TitleCard>
        </CardContainer>
        <ButtonsWrapper>
          <Button.NewPrimary
            href="/wizard/"
            onClick={GAEvent.openSemeinyiPageFirstScreen}
            size="large"
            type="link"
          >
            Подобрать сексолога
          </Button.NewPrimary>
        </ButtonsWrapper>
      </Content>
    </section>
  </Wrapper>
)
