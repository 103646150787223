import React from 'react'
import {
  Br,
  CardContainer,
  Container,
  Content,
  ImageWrapper,
  StepOneImage,
  StepThreeImage,
  StepTwoImage,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HowSessionIsGoing.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const HowSessionIsGoing = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper color="white">
            <Title.H2>
              Как проходит онлайн&#8288;-&#8288;прием <Br />с
              психологом&#8288;-&#8288;сексологом в YouTalk?
            </Title.H2>
          </TitleWrapper>
          <CardContainer>
            <Container>
              <ImageWrapper>
                <StepOneImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Medium semiBold>
                  Если вы хотели бы обратиться за помощью к психологу-сексологу,
                  но опасаетесь разглашения информации, не переживайте — сессия
                  с сексологом абсолютно конфиденциальна.
                </Text.Medium>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <StepTwoImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Medium semiBold>
                  Если специалисту понадобится вынести ваш случай на супервизию
                  (обсуждение с более опытным коллегой для поиска оптимального
                  решения вашего запроса), он обязательно спросит у вас на это
                  разрешение.
                </Text.Medium>
              </TextWrapper>
            </Container>
            <Container>
              <ImageWrapper>
                <StepThreeImage />
              </ImageWrapper>
              <TextWrapper>
                <Text.Medium semiBold>
                  Мы не ведём видео или аудио записи встреч с клиентами.
                  Анонимная консультация сексолога также возможна: мы не требуем
                  называть свои реальные имена, не спрашиваем фамилию — всё это
                  вы оставляете при заполнении анкеты по желанию.
                </Text.Medium>
              </TextWrapper>
            </Container>
          </CardContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
