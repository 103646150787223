import React from 'react'
import loveIcon from '../../../../static/img/psiholog-seksolog/love-icon.svg'
import messageIcon from '../../../../static/img/psiholog-seksolog/message-icon.svg'
import studyIcon from '../../../../static/img/psiholog-seksolog/study-icon.svg'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './WhyYouNeedSeksolog.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhyYouNeedSeksolog = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2>
                Почему стоит обратиться за помощью сексолога в YouTalk?
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Не любые курсы готовят сильных психологов-сексологов.
              </Text.Large>
              <Text.Large semiBold>
                Именно поэтому стоит обратиться за помощью сексолога YouTalk: мы
                тщательно проверяем образование и принимаем в команду
                специалистов, получивших качественное образование и прошедших
                хорошую практику.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <ListImage src={studyIcon} />
              <Text.Large semiBold>
                Лишь 6% психологов проходят все этапы отбора и попадают в
                команду.
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={loveIcon} />
              <Text.Large semiBold>
                Все наши специалисты имеют от 3х лет опыта и проходят тест на
                толерантность к разным социальным группам (по религии,
                идентичности, полу, весу, социальному статусу и т.п.).
              </Text.Large>
            </ListItem>
            <ListItem>
              <ListImage src={messageIcon} />
              <Text.Large semiBold>
                Обращаясь в YouTalk, вы можете быть уверены, что не попадете к
                шарлатану или неумелому специалисту.
              </Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
