import Masonry from 'react-masonry-css'
import React from 'react'
import Swiper from 'react-id-swiper'
import {
  Card,
  CardsWrapper,
  Col,
  MasonryWrapper,
  Row,
  Section,
  TitleWrapper
} from './Comments.style'
import { Grid } from '../../../../youtalk-storybook/src/ui'
import { SeksologCommentsCard } from './SeksologCommentsCard'
import {
  breakpointColumnsObj,
  commentsCard,
  paramsComments
} from './CommentsData'
import { selectDeviceSize } from '../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

export const Comments = () => {
  const wWidth = useSelector(selectDeviceSize)

  return (
    <Section>
      <Grid>
        <Row>
          <Col>
            <TitleWrapper>Вот что пишут о YouTalk наши клиенты</TitleWrapper>
            {wWidth > 767 ? (
              <MasonryWrapper>
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="masonry"
                  columnClassName="masonryCol"
                >
                  {commentsCard.map(
                    ({ img, imgAlt, name, age, post, title, desc }, i) => (
                      <Card key={i}>
                        <SeksologCommentsCard
                          age={age}
                          desc={desc}
                          img={img}
                          imgAlt={imgAlt}
                          name={name}
                          post={post}
                          title={title}
                        />
                      </Card>
                    )
                  )}
                </Masonry>
              </MasonryWrapper>
            ) : (
              <CardsWrapper>
                <Swiper {...paramsComments}>
                  {commentsCard.map(
                    ({ img, name, age, post, title, desc }, i) => (
                      <Card key={i}>
                        <SeksologCommentsCard
                          age={age}
                          desc={desc}
                          img={img}
                          imgAlt=""
                          name={name}
                          post={post}
                          title={title}
                        />
                      </Card>
                    )
                  )}
                </Swiper>
              </CardsWrapper>
            )}
          </Col>
        </Row>
      </Grid>
    </Section>
  )
}
