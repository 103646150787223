import styled from 'styled-components'
import { Montserrat, size } from '../../../constants'

export const Section = styled.section`
  padding: 60px 0 0;
  background-repeat: no-repeat;
  background-color: #dfebf9;
  background-size: 100%;
  overflow: hidden;

  @media (max-width: ${size.lg}) {
    padding: 72px 0;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;

  @media (max-width: ${size.md}) {
    margin: 0 -8px !important;
  }

  @media (max-width: ${size.sm}) {
    margin: 0 -20px !important;
  }

  @media (max-width: ${size.xs}) {
    margin: 0 -16px !important;
  }
`

export const Col = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
`

export const MasonryWrapper = styled.div`
  .masonry {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: ${size.sm}) {
      overflow-x: auto;
      align-items: flex-start;
      margin: 0 -20px;
      padding-left: 20px;

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
    }

    @media (max-width: ${size.xs}) {
      margin: 0 -16px;
      padding-left: 16px;
    }
  }

  .masonryCol {
    width: calc(25% - 24px);

    @media (max-width: ${size.md}) : {
      width: calc(33.333% - 24px);
    }

    @media (max-width: ${size.sm}) : {
      width: calc(50% - 24px);
    }
  }
`

export const TitleWrapper = styled.div`
  color: #333333;
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 40px;

  @media (max-width: ${size.md}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 24px;
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${size.sm}) {
    overflow-x: auto;
    align-items: flex-start;
    margin: 0 -20px;
    padding-left: 20px;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }

  @media (max-width: ${size.xs}) {
    margin: 0 -16px;
    padding-left: 16px;
  }
`

export const Card = styled.div`
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 20px 40px rgba(3, 178, 165, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;

  @media (max-width: ${size.sm}) {
    width: calc(100% - 36px) !important;
    min-width: calc(100% - 36px) !important;
    margin-right: 20px;
    margin-bottom: 0;
  }

  @media (max-width: ${size.xs}) {
    width: calc(100% - 24px) !important;
    min-width: calc(100% - 24px) !important;
    margin-right: 16px;
  }

  &:last-child {
    margin: 0 0 74px;

    @media (max-width: ${size.lg}) {
      margin-bottom: 0;
    }

    @media (max-width: ${size.sm}) {
      min-width: calc(100% - 16px);
      padding-right: 20px;
    }

    @media (max-width: ${size.xs}) {
      min-width: calc(100% - 8px);
      padding-right: 16px;
    }
  }
`

export const SeksologCommentsCardHead = styled.div`
  display: flex;
  margin-bottom: 16px;

  img {
    display: block;
    width: 72px;
    height: 72px;
    margin-right: 16px;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 4px;
  }
`

export const Name = styled.p`
  margin-bottom: 8px;
  font-family: ${Montserrat};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
`

export const Age = styled.p`
  margin-bottom: 8px;
  font-family: ${Montserrat};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: rgba(51, 51, 51, 0.4);
`

export const Post = styled.p`
  font-family: ${Montserrat};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: rgba(51, 51, 51, 1);
`

export const SeksologCommentsCardContent = styled.div``

export const Title = styled.h5`
  margin-bottom: 8px;
  font-family: ${Montserrat};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #333333;
  white-space: pre-line;
`

export const Description = styled.p`
  font-family: ${Montserrat};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  white-space: pre-line;
`
