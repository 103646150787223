export const commentsCard = [
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Наталья',
    age: '29 лет',
    post: 'Менеджер по продажам',
    title: 'Это было для меня открытие',
    desc: 'Очень интересная возможность писать в любое время в течение дня, довольно быстро после ответов моего консультанта я увидела свою ситуацию глубже, яснее. Это было для меня открытие, которое я бы не смогла совершить самостоятельно. Спасибо вам за ваш проект и работу.'
  },
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'Светлана',
    age: '32 года',
    post: 'Event-менеджер',
    title: 'Я стала работать эффективнее',
    desc: 'Благодаря YouTalk я стала работать эффективнее и успевать больше. Мне нравится, что я могу общаться со специалистом даже во время командировок или попав в пробку.'
  },
  {
    img: 'person5.svg',
    imgAlt:
      'Портрет бородатого кудрявого мужчины с черными волосами на светло-оранжевом фоне',
    name: 'Александр',
    age: '26 лет',
    post: 'Фрилансер',
    title:
      'Благодаря терапии я каждый\n день понемногу меняю себя\n и отношение к жизни',
    desc: 'Терапия с Татьяной перевернула мой внутренний мир! Я даже не думал, что так может быть. Я вроде бы всё хорошо про себя понимал, но дальше их изменить что-то — вообще никак. Благодаря терапии я каждый день понемногу меняю себя и отношение к жизни.\n Спасибо вам огромное за организацию такой помощи!'
  },
  {
    img: 'person2.svg',
    imgAlt:
      'Портрет мужчины в очках со светлыми кудрявыми волосами на синем фоне',
    name: 'Валентина',
    age: '40 лет',
    post: 'Переводчик',
    title: 'Больше не тревожусь так,\n как раньше',
    desc: 'Мне всё нравится. Чувствую, что помогает. Узнала кое-что новое, больше не тревожусь так, как раньше. В частности, успокаивает, что я работаю над своими проблемами, но и задания мне даются хорошие. В общем, довольна.'
  },
  {
    img: 'person3.svg',
    imgAlt: 'Портрет лысого мужчины с телефоном на зеленом фоне',
    name: 'Виталий',
    age: '21 год',
    post: 'Юрист',
    title: 'Большой прогресс для меня',
    desc: 'Ольга, спасибо Вам за чуткость и такт. Вам удавалось задавать вопросы и направлять мои мысли по-новому. Я ощутил, что рассуждать с самим собой это не то же самое, что объяснить свою ситуацию постороннему человеку. Большой прогресс для меня…'
  },
  {
    img: 'person4.svg',
    imgAlt: 'Портрет усатого мужчины с черными волосами на красном фоне',
    name: 'Сергей',
    age: '28 лет',
    post: 'Специалист технической поддержки',
    title: 'Перечитываю сообщения вновь и вновь, всё становится на свои места',
    desc: 'Я хочу выразить огромную благодарность Анастасии! Весной я брал неделю переписки с ней и сейчас перечитываю её сообщения вновь и вновь, все становится на свои места.'
  }
]

export const breakpointColumnsObj = {
  default: 3,
  1023: 2
}

export const paramsComments = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  preventClicks: false,
  preventClicksPropagation: false
}
