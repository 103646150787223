import React from 'react'
import {
  Age,
  Description,
  Name,
  Post,
  SeksologCommentsCardContent,
  SeksologCommentsCardHead,
  Title
} from './Comments.style'

export const SeksologCommentsCard = ({
  name,
  img,
  imgAlt,
  age,
  post,
  title,
  desc
}) => (
  <>
    <SeksologCommentsCardHead>
      <img alt={imgAlt} src={`../img/comments/${img}`} />
      <div>
        <Name>{name}</Name>
        <Age>{age}</Age>
        <Post>{post}</Post>
      </div>
    </SeksologCommentsCardHead>
    <SeksologCommentsCardContent>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Description dangerouslySetInnerHTML={{ __html: desc }} />
    </SeksologCommentsCardContent>
  </>
)
