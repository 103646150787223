import React from 'react'
import {
  Content,
  InfoCard,
  InfoCardImageHeader,
  InfoCardsContainer,
  ListItem,
  ListWrapper,
  ReasonOneSVG,
  ReasonThreeSVG,
  ReasonTwoSVG,
  TitleCard,
  TitleWrapper,
  Wrapper
} from './WhenConsultationNeed.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhenConsultationNeed = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>Когда нужна консультация сексолога?</Title.H2>
          </TitleWrapper>
          <InfoCardsContainer>
            <InfoCard>
              <InfoCardImageHeader>
                <ReasonOneSVG />
              </InfoCardImageHeader>
              <div>
                <TitleCard>
                  Если проблемы возникают у одного из партнеров:
                </TitleCard>
                <ListWrapper>
                  <ListItem>
                    <Text.Large semiBold>
                      Пропало влечение к партнеру
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Непонятно, нормально ли какое-то сексуальное поведение
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Трудно адаптироваться к возрастным изменениям (например,
                      возникли сложности с эрекцией, случилась менопауза,
                      появилась сухость влагалища и т.д.)
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Тяжело понять свои желания или особенности тела
                    </Text.Large>
                  </ListItem>
                </ListWrapper>
              </div>
            </InfoCard>

            <InfoCard>
              <InfoCardImageHeader>
                <ReasonTwoSVG />
              </InfoCardImageHeader>
              <div>
                <TitleCard>
                  Когда врачи не видят физиологических причин проблем:
                </TitleCard>
                <ListWrapper>
                  <ListItem>
                    <Text.Large semiBold>Бесплодия</Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Повторяющейся невынашиваемости плода
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>Вагинизма</Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Эректильной дисфункции и других расстройств
                    </Text.Large>
                  </ListItem>
                </ListWrapper>
              </div>
            </InfoCard>

            <InfoCard>
              <InfoCardImageHeader>
                <ReasonThreeSVG />
              </InfoCardImageHeader>
              <div>
                <TitleCard>Если проблемы возникли в паре:</TitleCard>
                <ListWrapper>
                  <ListItem>
                    <Text.Large semiBold>Не совпадают фантазии</Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      Одному из партнеров нужно гораздо больше секса, чем
                      другому
                    </Text.Large>
                  </ListItem>
                  <ListItem>
                    <Text.Large semiBold>
                      У партнеров слишком разные сексуальные предпочтения
                    </Text.Large>
                  </ListItem>
                </ListWrapper>
              </div>
            </InfoCard>
          </InfoCardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
