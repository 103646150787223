import reasonOneIcon from '../../../../static/img/psiholog-seksolog/reason-one.svg'
import reasonThreeIcon from '../../../../static/img/psiholog-seksolog/reason-three.svg'
import reasonTwoIcon from '../../../../static/img/psiholog-seksolog/reason-two.svg'
import styled from 'styled-components'
import { Montserrat } from '../../../constants'
import { size } from '../../../../youtalk-storybook/src/ui'

export const Wrapper = styled.div`
  background: #dfebf9;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const InfoCardsContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }
`

export const TitleCard = styled.div`
  color: #333;
  font-family: ${Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const InfoCard = styled.div`
  padding: 24px;
  border-radius: 32px;
  background: #f9fdfd;
  width: 384px;

  ${TitleCard} {
    margin-bottom: 16px;

    @media (max-width: ${size.sm}) {
      margin-bottom: 8px;
    }
  }

  @media (max-width: ${size.lg}) {
    width: 304px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    display: flex;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-left: 25px;
  list-style-type: disc;
`
export const ListItem = styled.li`
  gap: 8px;
  color: #333;
`

export const ReasonOneSVG = styled.img.attrs(() => ({
  src: reasonOneIcon
}))`
  display: block;
  margin: 0 auto;
`

export const ReasonTwoSVG = styled.img.attrs(() => ({
  src: reasonTwoIcon
}))`
  display: block;
  margin: 0 auto;
`

export const ReasonThreeSVG = styled.img.attrs(() => ({
  src: reasonThreeIcon
}))`
  display: block;
  margin: 0 auto;
`

export const InfoCardImageHeader = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${size.md}) {
    display: flex;
    margin-bottom: 0;
  }
`
