import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Content,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './HeroBlock.styles'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H1>Психолог-сексолог онлайн</Title.H1>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Запишитесь на консультацию с психологом-сексологом, для того,
                чтобы справиться с сексуальными проблемами
              </Text.Large>
              <SeoPriceForTitleBlock />
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать сексолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Психолог-сексолог онлайн" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
