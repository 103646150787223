import stepOneIcon from '../../../../static/img/psiholog-seksolog/step-one.svg'
import stepThreeIcon from '../../../../static/img/psiholog-seksolog/step-three.svg'
import stepTwoIcon from '../../../../static/img/psiholog-seksolog/step-two.svg'
import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.lg}) {
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
    text-align: center;

    @media (max-width: ${size.md}) {
      text-align: left;
    }
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`
export const Br = styled.br`
  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const TitleCardWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 282px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: white;
  border-radius: 32px;

  @media (max-width: ${size.lg}) {
    height: 378px;
  }

  @media (max-width: ${size.md}) {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: auto;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }
`

export const StepOneImage = styled.img.attrs(() => ({
  src: stepOneIcon
}))``

export const StepTwoImage = styled.img.attrs(() => ({
  src: stepTwoIcon
}))``

export const StepThreeImage = styled.img.attrs(() => ({
  src: stepThreeIcon
}))``

export const TextWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
