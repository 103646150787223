import clockIcon from '../../../../static/img/psiholog-seksolog/clock.svg'
import listIcon from '../../../../static/img/psiholog-seksolog/list.svg'
import messageIcon from '../../../../static/img/psiholog-seksolog/message.svg'
import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.lg}) {
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
    text-align: center;
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.color ?? '#333'};
  margin-bottom: 32px;

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
  height: 351px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  background: white;
  border-radius: 32px;

  @media (max-width: ${size.lg}) {
    height: 403px;
  }

  @media (max-width: ${size.md}) {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: auto;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }
`

export const ClockImage = styled.img.attrs(() => ({
  src: clockIcon
}))``

export const MessageImage = styled.img.attrs(() => ({
  src: messageIcon
}))``

export const ListImage = styled.img.attrs(() => ({
  src: listIcon
}))``

export const TextWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
